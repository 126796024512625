import useMutateUserAnalytics from './mutations/use_mutate_user_analytics'

export default function useAllAnalytics() {
  const {getFocusTask} = useMutateUserAnalytics()

  const analytics = {
    getFocusTask: getFocusTask,
  }

  return {analytics}
}
