import useMutationDefault from 'hooks/mutations/use_mutation_default'
import {useMutation} from 'react-query'
import {tabjayNodeApi} from 'utils/tabjay_api_utils'
import {URL} from 'utils/urls'

export default function useMutateUsersPlans() {
  const {defaultMutation} = useMutationDefault()
  const queriesToInvalidate = [URL.route.current_plan, URL.route.current_user]

  const addPlanUser = useMutation(
    async (data) => await tabjayNodeApi.post(URL.route.users_plans, data), defaultMutation(queriesToInvalidate)
  )

  const updatePlanUser = useMutation(
    async (data) => await tabjayNodeApi.patch(`${URL.route.users_plans}`, data), defaultMutation(queriesToInvalidate)
  )

  const removePlanUser = useMutation(
    async (data) => await tabjayNodeApi.delete(`${URL.route.users_plans}/${data.id}`, data), defaultMutation(queriesToInvalidate)
  )

  return {addPlanUser, updatePlanUser, removePlanUser}
}
