import Script from 'next/script'

const GoogleTagManager = () => {
  if (process.env.NEXT_PUBLIC_CLIENT_ENV !== 'production') return null

  return (
    <>
      <Script src="https://www.googletagmanager.com/gtag/js?id=G-5SD0G6ZYHP" />
      <Script id='google-tag-manager-script'>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-5SD0G6ZYHP');
        `}
      </Script>
    </>
  )
}

export default GoogleTagManager