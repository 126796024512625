const ERROR = {
  // NOTE: depending on how many types of erros we have. We might be able to
  // remove tabjay/client/api and just assume that ERROR.internal_server_error
  // is regarding the tabjay api and then name space other apis like google and confluenc.
  tabjay: {
    client: {

    },
    api: {
      message: 'Invalid Response',
      // description will come from the server execpt for 500 errors
      // internal_server_error
      internal_server_error: {
        message: 'Server Error',
        description: 'Something went wrong on our end'
      },
    },
  },

  google: {
    unauthorized: 'You have not authorized Tabjay to access your Google documents.',
    unauthorized_title: 'Unauthorized with Google',
    internal_server_error: 'Something went wrong with the server',
    docs: {
      access_denied: 'You do not have permission to view this document. Please contact the document owner to grant you access.',
      default: 'Document is unavailable due to unknown error.',
      lacks_support: 'Unfortunately this document type is not supported in Tabjay.'
    },
    // drive is backend only and used for metadata
    // drive: {},
  },

  confluence: {
    unauthorized_title: 'Unauthorized with Atlassian',
    unauthorized: (domain => {
      return `
        You are not authorized to access the cloud site this link is located in (${domain}).
        Please authorize Tabjay to access this cloud site. If you do not see this site in your list of authorizable sites,
        contact an admin from this site to give you access first.
        `
    })
  },
}

export {ERROR}
