import {Link} from '@brikit/tabjay-ui-kit'
import useMutationDefault from 'hooks/mutations/use_mutation_default'
import useAllAnalytics from 'hooks/use_all_analytics'
import {useMutation, useQueryClient} from 'react-query'
import GoogleApi from 'services/google_api'
import {clearPreviousFormData} from 'utils/authorization_prompts'
import {tabjayNodeApi} from 'utils/tabjay_api_utils'
import {URL} from 'utils/urls'

export default function useMutateLink() {
  const {defaultMutation} = useMutationDefault()
  const {analytics} = useAllAnalytics()
  const queriesToInvalidate = [URL.route.current_plan]
  const queryClient = useQueryClient()

  const editLink = useMutation(
    (data) => tabjayNodeApi.patch(`${URL.route.links}/${data.id}`, data),
    defaultMutation(queriesToInvalidate, {
    })
  )

  const removeLink = useMutation(
    (data) => tabjayNodeApi.delete(`${URL.route.links}/${data.id}`),
    defaultMutation(queriesToInvalidate, {
    })
  )

  const createLinks = useMutation(
    (data) => tabjayNodeApi.post(URL.route.links, data),
    defaultMutation(queriesToInvalidate, {
      createLink: true,
      closeModal: true,
      callBack: (data) => {
        data.forEach(link => {
          new Link(link)
          // analytics.viewLink.mutate({userId: user.id, linkId: link.id})
        })

        // Update thumbnail cache for new google links
        const newGoogleLinks = data.filter(r => r.url.includes('docs.google.com/'))
        GoogleApi.updateThumbnailCache(newGoogleLinks, queryClient)

        // clear authorization form data in local storage
        clearPreviousFormData()
      }
    })
  )

  return {editLink, removeLink, createLinks}
}
