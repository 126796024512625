import {useRouter} from 'next/router'
import {useEffect, useState} from 'react'

export default function usePageLoading() {
  const router = useRouter()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const handleRouteChange = (url, {shallow}) => {
      setLoading(true)
    }

    router.events.on('routeChangeStart', handleRouteChange)

    // If the component is unmounted, unsubscribe from the event with the `off` method:
    return () => {
      setLoading(false)
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router])

  return {loading}
}