import useMutationDefault from 'hooks/mutations/use_mutation_default'
import {useMutation} from 'react-query'
import {tabjayNodeApi} from 'utils/tabjay_api_utils'
import {URL} from 'utils/urls'

export default function useMutateUsersTasks() {
  const {defaultMutation} = useMutationDefault()
  const queriesToInvalidate = [URL.route.current_plan]

  const toggleTaskComplete = useMutation(
    (data) => tabjayNodeApi.patch(URL.route.toggle_task_complete, data), defaultMutation(queriesToInvalidate)
  )

  return {toggleTaskComplete}
}
