import useMutationDefault from 'hooks/mutations/use_mutation_default'
import {useMutation} from 'react-query'
import {tabjayNodeApi} from 'utils/tabjay_api_utils'
import {URL} from 'utils/urls'

export default function useMutateTask() {
  const {defaultMutation} = useMutationDefault()
  const queriesToInvalidate = [URL.route.current_plan]

  const editTask = useMutation(
    (data) => tabjayNodeApi.patch(`${URL.route.tasks}/${data.id}`, data),
    defaultMutation(queriesToInvalidate, {
    })
  )

  const createTask = useMutation(
    (data) => tabjayNodeApi.post(URL.route.tasks, data),
    defaultMutation(queriesToInvalidate, {
    })
  )

  const resetShareLinks = useMutation(
    (data) => tabjayNodeApi.patch(URL.route.reset_share_links, data),
    defaultMutation(queriesToInvalidate)
  )

  const removeTask = useMutation(
    (data) => tabjayNodeApi.delete(`${URL.route.tasks}/${data.id}`, {data: {...data}}),
    defaultMutation([...queriesToInvalidate, URL.route.current_user])
  )

  const leaveTask = useMutation(
    (data) => tabjayNodeApi.delete(`${URL.route.tasks}/${data.id}`, {data: {...data, leave: true}}),
    defaultMutation([...queriesToInvalidate, URL.route.current_user])
  )

  return {editTask, removeTask, createTask, resetShareLinks, leaveTask}
}
