import useMutationDefault from 'hooks/mutations/use_mutation_default'
import {useMutation} from 'react-query'
import {tabjayNodeApi} from 'utils/tabjay_api_utils'
import {URL} from 'utils/urls'

export default function useMutateUsersLinks() {
  const {defaultMutation} = useMutationDefault()
  const queriesToInvalidate = [URL.route.current_plan]

  const updateLinkHidden = useMutation(
    (data) => tabjayNodeApi.patch(URL.route.update_link_hidden, data), defaultMutation(queriesToInvalidate)
  )

  return {updateLinkHidden}
}
