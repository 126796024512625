import useMutateLink from 'hooks/mutations/use_mutate_link'
import useMutateTask from 'hooks/mutations/use_mutate_task'
import useMutateUsersTasks from 'hooks/mutations/use_mutate_users_tasks'
import {useQueryClient} from 'react-query'
import {URL} from 'utils/urls'
import useMutateInvites from './use_mutate_invites'
import useMutatePlan from './use_mutate_plan'
import useMutateTrialQuestions from './use_mutate_trial_questions'
import useMutateUser from './use_mutate_user'
import useMutateUsersLinks from './use_mutate_users_links'
import useMutateUsersPlans from './use_mutate_users_plans'

export default function useAllMutations() {
  const {editTask, removeTask, createTask, leaveTask} = useMutateTask()
  const {createLinks, editLink, removeLink} = useMutateLink()
  const {deleteUser, updateUser, signoutUser, revokeAuthorization} = useMutateUser()
  const {createPlan, deletePlan, updatePlan, deactivatePlan, reactivatePlan, addPlanSeat, updateBilling} = useMutatePlan()
  const {toggleTaskComplete} = useMutateUsersTasks()
  const {updateLinkHidden} = useMutateUsersLinks()
  const {editTrialQuestions, createTrialQuestions} = useMutateTrialQuestions()
  const {getInvite, createInvite, acceptInvite} = useMutateInvites()
  const {addPlanUser, updatePlanUser, removePlanUser} = useMutateUsersPlans()
  const queryClient = useQueryClient()

  const mutations = {
    createPlan: createPlan,
    updatePlan: updatePlan,
    deletePlan: deletePlan,
    reactivatePlan: reactivatePlan,
    deactivatePlan: deactivatePlan,
    addPlanSeat: addPlanSeat,
    updateBilling: updateBilling,

    editTask: editTask,
    removeTask: removeTask,
    createTask: createTask,
    leaveTask: leaveTask,

    createLinks: createLinks,
    editLink: editLink,
    removeLink: removeLink,

    deleteUser: deleteUser,
    updateUser: updateUser,
    signoutUser: signoutUser,
    revokeAuthorization: revokeAuthorization,

    toggleTaskComplete: toggleTaskComplete,
    updateLinkHidden: updateLinkHidden,
    editTrialQuestions: editTrialQuestions,
    createTrialQuestions: createTrialQuestions,

    getInvite: getInvite,
    createInvite: createInvite,
    acceptInvite: acceptInvite,

    addPlanUser: addPlanUser,
    updatePlanUser: updatePlanUser,
    updateUserRole: updatePlanUser,
    removePlanUser: removePlanUser,

    invalidateQueries: (queries = []) => queryClient.invalidateQueries(queries),
    invalidatePlanQuery: () => queryClient.invalidateQueries([URL.route.current_plan])
  }

  return {mutations}
}
