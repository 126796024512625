import useMutationDefault from 'hooks/mutations/use_mutation_default'
import {useMutation} from 'react-query'
import {tabjayNodeApi} from 'utils/tabjay_api_utils'
import {URL} from 'utils/urls'

export default function useMutateInvites() {
  const {defaultMutation} = useMutationDefault()
  const queriesToInvalidate = [URL.route.current_user, URL.route.current_plan]

  const getInvite = async (data) => {
    return await tabjayNodeApi.get(`${URL.route.invite}/${data.code}`)
  }

  const createInvite = useMutation(
    (data) => tabjayNodeApi.post(URL.route.invite, data),
    defaultMutation([URL.route.current_plan], {})
  )

  const acceptInvite = useMutation(
    (data) => tabjayNodeApi.post(URL.route.accept_invite, data),
    defaultMutation(queriesToInvalidate, {
      callBack: (data) => {
        localStorage.removeItem('inviteCode')
      }
    })
  )

  return {getInvite, createInvite, acceptInvite}
}
