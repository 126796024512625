import Head from 'next/head'

const isStaging = process.env.NEXT_PUBLIC_CLIENT_ENV === 'staging'
const isRelease = process.env.NEXT_PUBLIC_CLIENT_ENV === 'release'
const noIndex = isStaging || isRelease

const NoIndexStaging = () => {
  return (
    noIndex
      ? <Head><meta name="robots" content="noindex" /></Head>
      : null
  )
}

export default NoIndexStaging