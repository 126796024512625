import {ifBrowserEnv, StagingPasswordPage} from '@brikit/tabjay-ui-kit'
import {useEffect, useState} from 'react'

const stagingPassword = process.env.NEXT_PUBLIC_SPLASH_PAGE_PASSWORD
const productionEnv = process.env.NEXT_PUBLIC_CLIENT_ENV === 'production'

const SplashPage = (props) => {

  const verifyPassword = () => {
    const splashPassword = ifBrowserEnv(() => localStorage.getItem('splashPassword'))
    return splashPassword === stagingPassword
  }

  const [secret, setSecret] = useState({})
  const [showSplash, setShowSplash] = useState(null)

  const submitSecret = () => {
    ifBrowserEnv(() => localStorage.setItem('splashPassword', secret.value))
    if (secret.value !== stagingPassword) {
      setShowSplash(true)
      alert('Incorrect Password')
    }
    else {setShowSplash(false)}
    setSecret({value: ''})
  }

  useEffect(() => {
    if (!verifyPassword()) {setShowSplash(true)}
  }, [])

  if (productionEnv) {return props.children}

  return (
    showSplash
      ? <StagingPasswordPage
        secret={secret}
        setSecret={setSecret}
        submitSecret={submitSecret}
      />
      : props.children
  )
}

export default SplashPage
