import {Task, User} from '@brikit/tabjay-ui-kit'
import useMutationDefault from 'hooks/mutations/use_mutation_default'
import {useQueryClient} from 'react-query'
import {URL} from 'utils/urls'

export default function useMutateUserAnalytics() {
  const queryKey = URL.route.current_user_analytics
  const query = [queryKey]
  const {defaultMutation} = useMutationDefault()
  const currentUser = User.getCurrentUser()

  const queryClient = useQueryClient()
  const queryData = queryClient.getQueryData("/current_user_analytics")

  const getFocusTask = () => {return Task.findById(queryData?.focusTask.id)}

  return {getFocusTask}
}
