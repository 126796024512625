import {RouteContext, ROUTES} from '@brikit/tabjay-ui-kit'
import useMutationDefault from 'hooks/mutations/use_mutation_default'
import {useContext} from 'react'
import {useMutation} from 'react-query'
import {signOutHelper} from 'utils/helper_functions'
import {tabjayNodeApi} from 'utils/tabjay_api_utils'
import {URL} from 'utils/urls'

export default function useMutatePlan() {
  const {defaultMutation} = useMutationDefault()
  const queriesToInvalidate = [URL.route.current_users, URL.route.current_plan]
  const {setRoute} = useContext(RouteContext)

  const addPlanSeat = useMutation(
    async (data) => await tabjayNodeApi.patch(`${URL.route.plans}/${data.id}`, {...data, addPlanSeat: true}),
    defaultMutation(queriesToInvalidate)
  )

  const createPlan = useMutation(
    async (data) => await tabjayNodeApi.post(URL.route.plans, data),
    defaultMutation(queriesToInvalidate, {callBack: () => setRoute(ROUTES.TASKS)})
  )

  const updatePlan = useMutation(
    async (data) => await tabjayNodeApi.patch(`${URL.route.plans}/${data.id}`, data),
    defaultMutation(queriesToInvalidate)
  )

  const deactivatePlan = useMutation(
    async (data) => await tabjayNodeApi.patch(`${URL.route.plans}/${data.id}`, {...data, deactivate: true}),
    defaultMutation(queriesToInvalidate)
  )

  const deletePlan = useMutation(
    async (data) => {
      await tabjayNodeApi.delete(`${URL.route.plans}/${data.id}`)
    },
    defaultMutation(queriesToInvalidate, {redirectTo: 'dashbaord', callBack: () => signOutHelper(URL.route.plan_deleted)})
  )

  const reactivatePlan = useMutation(
    async (data) => await tabjayNodeApi.patch(`${URL.route.plans}/${data.id}`, {...data, reactivate: true}),
    defaultMutation(queriesToInvalidate, {redirectTo: 'dashboard'})
  )
  const updateBilling = useMutation(
    async (data) => await tabjayNodeApi.patch(`${URL.route.plans}/${data.id}`, {...data, updateBilling: true}),
    defaultMutation(queriesToInvalidate, {redirectTo: 'dashboard'})
  )

  return {createPlan, deletePlan, updatePlan, deactivatePlan, reactivatePlan, addPlanSeat, updateBilling}
}
