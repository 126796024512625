import {SnackBarContext} from '@brikit/tabjay-ui-kit'
import {ERROR} from 'copy/error_messages'
import {useContext} from 'react'

export default function useErrorHandling() {
  const {showSnack} = useContext(SnackBarContext)

  const defaultMutationError = (error, variables, context) => {
    const res = error.response.data
    const status = error.status

    if (status >= 400 && status < 500) {
      return showSnack({
        type: 'error',
        message: res.message,
        details: res.details
      })
    }

    if (error.response.status >= 500) {
      return showSnack({
        type: 'error',
        message: ERROR.tabjay.api.internal_server_error.message,
        details: ERROR.tabjay.api.internal_server_error.description
      })
    }
  }

  return {defaultMutationError}
}
