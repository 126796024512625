import {ModalContext, Mutations} from '@brikit/tabjay-ui-kit'
import useErrorHandling from 'hooks/use_error_handling'
import {useRouter} from 'next/router'
import {useContext} from 'react'
import {useQueryClient} from 'react-query'
import {URL} from 'utils/urls'

export default function useMutationDefault() {
  const queryClient = useQueryClient()
  const navigate = useRouter()
  const {hideModal} = useContext(ModalContext)
  const {defaultMutationError} = useErrorHandling()

  const invalidateCache = (queryCache) => {
    //NOTE: We can't pass an array into the invalidateQueries function. We have to call it for each key.
    //https://react-query.tanstack.com/guides/query-invalidation
    queryCache.forEach((key) => {
      queryClient.invalidateQueries({queryKey: key})
    })
  }

  const addNewLinkToCache = (queryClient, queryCache, newItem) => {
    queryClient.setQueryData(queryCache, oldData => {
      // TODO: Fix this hardcoded links line.
      // oldData.links.push(newItem)
    })
  }

  const redirectTo = (assetPage, newItem) => {
    const redirectMap = {
      newTask: `${URL.route.task}/${newItem.id}`,
      dashboard: URL.route.root,
      signIn: URL.route.sign_in
    }

    redirectMap[assetPage]
      ? navigate.push(redirectMap[assetPage])
      : console.error(`Invalid redirect. redirectTo options are ${Object.keys(redirectMap)}`)
  }

  const defaultMutation = (queryCache, options = {create: false, redirectTo: '', callBack: null, closeModal: true, reload: false}) => {
    return {
      onSuccess: (res) => {
        if (res?.status >= 200) {
          const newItem = res?.data
          invalidateCache(queryCache)

          if (options.redirectTo) {redirectTo(options.redirectTo, newItem)}
          if (options.reload) {window.location.reload()}
          if (options.closeModal) {hideModal()}
          if (options.callBack) {options.callBack(newItem)}
          if (Mutations.callBack) {
            Mutations.callBack(newItem)
            Mutations.callBack = null
          }
          // if (options.createLink) {addNewLinkToCache(queryClient, queryCache, newItem)}
        }

        // if (res.status === 200) {
        //   return showSnack({
        //     type: 'success',
        //     message: errorData.message,
        //     details: errorData.details
        //   })
        // }

        // if (res.status === 201) {
        //   return showSnack({
        //     type: 'success',
        //     message: errorData.message,
        //     details: errorData.details
        //   })
        // }
      },
      onError: defaultMutationError
    }
  }

  return {defaultMutation, invalidateCache, defaultMutationError}
}
