import useMutationDefault from 'hooks/mutations/use_mutation_default'
import {useMutation} from 'react-query'
import {tabjayNodeApi} from 'utils/tabjay_api_utils'
import {URL} from 'utils/urls'

export default function useMutateTrialQuestions() {
  const {defaultMutation} = useMutationDefault()
  const queriesToInvalidate = [URL.route.current_plan, URL.route.current_user]

  const editTrialQuestions = useMutation(
    (data) => tabjayNodeApi.patch(URL.route.trial_questions, data),
    defaultMutation(queriesToInvalidate, {redirectTo: 'dashboard'})
  )

  const createTrialQuestions = useMutation(
    (data) => tabjayNodeApi.post(URL.route.trial_questions, data),
    defaultMutation(queriesToInvalidate, {redirectTo: 'dashboard'})
  )

  return {editTrialQuestions, createTrialQuestions}
}
